// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer-container {
	grid-area: footer;
	display: flex;
	flex-direction: row;
	align-items: center;
}

.logo-container {
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	width: 100%;
}

.logo-image {
	display: flex;
	justify-content: center;
	height: 6em;
	padding: 0 .25em;
	margin:	auto;
	transition:
		height .5s;
}

.logo-image:hover {
	cursor: pointer;
	height: 8em;
}`, "",{"version":3,"sources":["webpack://./src/components/Footer/footer.style.css"],"names":[],"mappings":"AAAA;CACC,iBAAiB;CACjB,aAAa;CACb,mBAAmB;CACnB,mBAAmB;AACpB;;AAEA;CACC,aAAa;CACb,mBAAmB;CACnB,6BAA6B;CAC7B,WAAW;AACZ;;AAEA;CACC,aAAa;CACb,uBAAuB;CACvB,WAAW;CACX,gBAAgB;CAChB,YAAY;CACZ;YACW;AACZ;;AAEA;CACC,eAAe;CACf,WAAW;AACZ","sourcesContent":[".footer-container {\n\tgrid-area: footer;\n\tdisplay: flex;\n\tflex-direction: row;\n\talign-items: center;\n}\n\n.logo-container {\n\tdisplay: flex;\n\tflex-direction: row;\n\tjustify-content: space-evenly;\n\twidth: 100%;\n}\n\n.logo-image {\n\tdisplay: flex;\n\tjustify-content: center;\n\theight: 6em;\n\tpadding: 0 .25em;\n\tmargin:\tauto;\n\ttransition:\n\t\theight .5s;\n}\n\n.logo-image:hover {\n\tcursor: pointer;\n\theight: 8em;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
