// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.map-container {
	min-height: 100%;
	width: 100%;
	border: .125em solid var(--ac-brown);
	border-radius: .25em;
}

@media(width > 450px) {
	.map-container {
		max-width: 50em;
	}
}`, "",{"version":3,"sources":["webpack://./src/components/SlideShow/googleMap/serviceAreaMap.style.css"],"names":[],"mappings":"AAAA;CACC,gBAAgB;CAChB,WAAW;CACX,oCAAoC;CACpC,oBAAoB;AACrB;;AAEA;CACC;EACC,eAAe;CAChB;AACD","sourcesContent":[".map-container {\n\tmin-height: 100%;\n\twidth: 100%;\n\tborder: .125em solid var(--ac-brown);\n\tborder-radius: .25em;\n}\n\n@media(width > 450px) {\n\t.map-container {\n\t\tmax-width: 50em;\n\t}\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
