// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.slide-show-container {
	display: grid;
	grid-area: main;
	overflow-y: scroll;
	scroll-snap-type: y mandatory;
}

.slide-topic-container {
	display: grid;
	grid-template-rows: 6em calc(100% - 6em);
	height: var(--body-height);
	scroll-snap-align: start;
	scroll-snap-stop: always;
	text-align: center;
	font-family: IMFell-reg;
}

.slide-topic-header {
	grid-row: 1;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	font-family: inherit;
	font-size: 2.5em;
	background-color: var(--ac-brown);
	color: var(--ac-blue);
	border-top: .15rem solid var(--ac-orange);
	border-bottom: .15rem solid var(--ac-orange);
}

.slide-topic-body {
	grid-row: 2;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	height: 100%;
}

/*univervaly applied to all card bodies*/
.slide-topic-card-body {
	margin: 0.25em .15em .05em .15em;
	height: var(--body-height);
	color: var(--ac-brown);
}

@media (width > 450px) {
	.slide-topic-card-body {
/*		width: 30em;*/
/*		border: 2px solid red;*/
	}
}
`, "",{"version":3,"sources":["webpack://./src/components/SlideShow/slideShow.style.css"],"names":[],"mappings":"AAAA;CACC,aAAa;CACb,eAAe;CACf,kBAAkB;CAClB,6BAA6B;AAC9B;;AAEA;CACC,aAAa;CACb,wCAAwC;CACxC,0BAA0B;CAC1B,wBAAwB;CACxB,wBAAwB;CACxB,kBAAkB;CAClB,uBAAuB;AACxB;;AAEA;CACC,WAAW;CACX,aAAa;CACb,sBAAsB;CACtB,mBAAmB;CACnB,uBAAuB;;CAEvB,oBAAoB;CACpB,gBAAgB;CAChB,iCAAiC;CACjC,qBAAqB;CACrB,yCAAyC;CACzC,4CAA4C;AAC7C;;AAEA;CACC,WAAW;CACX,aAAa;CACb,sBAAsB;CACtB,mBAAmB;CACnB,2BAA2B;CAC3B,YAAY;AACb;;AAEA,wCAAwC;AACxC;CACC,gCAAgC;CAChC,0BAA0B;CAC1B,sBAAsB;AACvB;;AAEA;CACC;AACD,iBAAiB;AACjB,2BAA2B;CAC1B;AACD","sourcesContent":[".slide-show-container {\n\tdisplay: grid;\n\tgrid-area: main;\n\toverflow-y: scroll;\n\tscroll-snap-type: y mandatory;\n}\n\n.slide-topic-container {\n\tdisplay: grid;\n\tgrid-template-rows: 6em calc(100% - 6em);\n\theight: var(--body-height);\n\tscroll-snap-align: start;\n\tscroll-snap-stop: always;\n\ttext-align: center;\n\tfont-family: IMFell-reg;\n}\n\n.slide-topic-header {\n\tgrid-row: 1;\n\tdisplay: flex;\n\tflex-direction: column;\n\talign-items: center;\n\tjustify-content: center;\n\n\tfont-family: inherit;\n\tfont-size: 2.5em;\n\tbackground-color: var(--ac-brown);\n\tcolor: var(--ac-blue);\n\tborder-top: .15rem solid var(--ac-orange);\n\tborder-bottom: .15rem solid var(--ac-orange);\n}\n\n.slide-topic-body {\n\tgrid-row: 2;\n\tdisplay: flex;\n\tflex-direction: column;\n\talign-items: center;\n\tjustify-content: flex-start;\n\theight: 100%;\n}\n\n/*univervaly applied to all card bodies*/\n.slide-topic-card-body {\n\tmargin: 0.25em .15em .05em .15em;\n\theight: var(--body-height);\n\tcolor: var(--ac-brown);\n}\n\n@media (width > 450px) {\n\t.slide-topic-card-body {\n/*\t\twidth: 30em;*/\n/*\t\tborder: 2px solid red;*/\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
