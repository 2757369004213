export const reviewsStub = [
	{
		review: "The guys from ArborCraft did an excellent job!",
		author: "Aaron G.",
	},
	{
		review: "The quality of work was as good as I expected",
		author: "David Benes",
	},
	{
		review: "The tree injections he gave my Linden tree made the canopy look great this summer!",
		author: "Gregg",
	},
	{
		review: "They cleaned up very thoroughly after.",
		author: "Jordan H.",
	},
];