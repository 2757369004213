// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.about-us-container {
	
}

.about-us-logo {
	height: 20em;
}

.about-us-text {
	font-size: 2.25rem;
}`, "",{"version":3,"sources":["webpack://./src/components/SlideShow/aboutUs/aboutUs.style.css"],"names":[],"mappings":"AAAA;;AAEA;;AAEA;CACC,YAAY;AACb;;AAEA;CACC,kBAAkB;AACnB","sourcesContent":[".about-us-container {\n\t\n}\n\n.about-us-logo {\n\theight: 20em;\n}\n\n.about-us-text {\n\tfont-size: 2.25rem;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
