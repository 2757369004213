// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.portfolio-container {
}

.portfolio-img {
	height: 20em;
	border-radius: 1em;
}

.portfolio-text {
	font-size: 2.25rem;
}`, "",{"version":3,"sources":["webpack://./src/components/SlideShow/portfolio/portfolio.style.css"],"names":[],"mappings":"AAAA;AACA;;AAEA;CACC,YAAY;CACZ,kBAAkB;AACnB;;AAEA;CACC,kBAAkB;AACnB","sourcesContent":[".portfolio-container {\n}\n\n.portfolio-img {\n\theight: 20em;\n\tborder-radius: 1em;\n}\n\n.portfolio-text {\n\tfont-size: 2.25rem;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
