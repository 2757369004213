// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.services-body-container {
	display: grid;
	justify-content: space-evenly;
	height: 100%;
}

.service-card-container {
	margin: .15em 1.25em;
}

.service-type-header-text {
	font-size: 2.25rem;
}

.service-type-body-text {
	font-size: 1.5rem;
}`, "",{"version":3,"sources":["webpack://./src/components/SlideShow/services/services.style.css"],"names":[],"mappings":"AAAA;CACC,aAAa;CACb,6BAA6B;CAC7B,YAAY;AACb;;AAEA;CACC,oBAAoB;AACrB;;AAEA;CACC,kBAAkB;AACnB;;AAEA;CACC,iBAAiB;AAClB","sourcesContent":[".services-body-container {\n\tdisplay: grid;\n\tjustify-content: space-evenly;\n\theight: 100%;\n}\n\n.service-card-container {\n\tmargin: .15em 1.25em;\n}\n\n.service-type-header-text {\n\tfont-size: 2.25rem;\n}\n\n.service-type-body-text {\n\tfont-size: 1.5rem;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
