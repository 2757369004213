// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.reviews-body-container {
	display: grid;
	justify-content: space-evenly;
	height: 100%;
}

.review-card-container {
	margin: .25em 1.25em;
}

.review-text {
	font-size: 1.8rem;
}

.author-text {
	font-size: 2.25rem;
}`, "",{"version":3,"sources":["webpack://./src/components/SlideShow/reviews/reviews.style.css"],"names":[],"mappings":"AAAA;CACC,aAAa;CACb,6BAA6B;CAC7B,YAAY;AACb;;AAEA;CACC,oBAAoB;AACrB;;AAEA;CACC,iBAAiB;AAClB;;AAEA;CACC,kBAAkB;AACnB","sourcesContent":[".reviews-body-container {\n\tdisplay: grid;\n\tjustify-content: space-evenly;\n\theight: 100%;\n}\n\n.review-card-container {\n\tmargin: .25em 1.25em;\n}\n\n.review-text {\n\tfont-size: 1.8rem;\n}\n\n.author-text {\n\tfont-size: 2.25rem;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
